import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import { openWhatsApp, oprnWhatsApp } from "../../utils";

export default function Pricing() {
  return (
    <Wrapper id="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Check Our Pricing</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="monitor"
                price="$499.99/mo"
                title="Digital Signage System"
                text="Elevate your business with our advnced digital signage solution, designed to engage audiences with dynamic displays and powerful content management."
                offers={[
                  { name: "Unlimited Screens", cheked: true },
                  { name: "Resale Available", cheked: true },
                  { name: "Robust CMS System", cheked: true },
                  { name: "24/7 Support", cheked: true },
                ]}
                action={() => openWhatsApp()}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$300/mo"
                title="Custom Software Development"
                text="Get tailored software solutions that are built to meet your business needs, providing scalability, efficiency, and seamless integration."
                offers={[
                  { name: "Custom-Built Solutions", cheked: true },
                  { name: "Agile Development Process", cheked: true },
                  { name: "Cross-Platform Support", cheked: true },
                  { name: "Ongoing Maintenance", cheked: true },
                  { name: "Cloud Integration", cheked: false },
                ]}
                action={() => openWhatsApp()}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$200/mo"
                title="Website Development"
                text="Create stunning websites that not only look great but perform excellently, ensuring high conversion rates and user satisfaction."
                offers={[
                  { name: "Responsive Design", cheked: true },
                  { name: "SEO Optimization", cheked: true },
                  { name: "E-commerce Ready", cheked: true },
                  { name: "Fast Loading Speeds", cheked: true },
                  { name: "Free Domain for 1 Year", cheked: true },
                ]}
                action={() => openWhatsApp()}
              />
            </TableBox>
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto
  }
`;




